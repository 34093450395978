import { Anchor, Box, Flex, MediaQuery, Text } from "@mantine/core"
import Link from "next/link"

export function AppFooter() {
  const year = new Date().getFullYear()
  return (
    <Box c="dark" py="xl" fz="sm" display="none">
      <Flex align="flex-end" direction="column">
        <Text ta="right" fz={{ base: "xs", sm: "sm", md: "md" }}>
          &copy; {year} NATA Compliance Services
          <MediaQuery largerThan="sm" styles={{ display: "none" }}>
            <Text span>
              <br />
            </Text>
          </MediaQuery>
          <MediaQuery smallerThan="sm" styles={{ display: "none" }}>
            <Text span>{" - "}</Text>
          </MediaQuery>
          all rights reserved{" "}
        </Text>
        <Flex gap="xs">
          <Anchor href="https://info.natacs.aero/privacy-statement" c="dark">
            Privacy
          </Anchor>
          <Anchor href="https://info.natacs.aero/terms-and-conditions-of-use" c="dark">
            Terms
          </Anchor>
        </Flex>
      </Flex>
    </Box>
  )
}
